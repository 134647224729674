<template>
  <div class="order_template_wrapper">
    <div class="content">
      <div v-if="step === 'first'">
        <div class="common">
          <div class="title gap-12">开单基础信息</div>
          <a-form-model ref="saleOrderForm" :model="form" :wrapper-col="wrapperCol" :rules="rules">
            <a-row>
              <a-col :span="16">
                <a-form-model-item label="收货信息" prop="addressId">
                  <a-select v-model="form.addressId" placeholder="请选择" @change="handleAddressChange">
                    <a-select-option
                      v-for="address in addressInfoList"
                      :key="address.addressId"
                      :value="address.addressId"
                    >
                      {{
                        `${address.name}_${isDirectlySalesCompany ? address.mobilePhone : address.phone}_${address.provinceName}${address.cityName}${address.areaName}${isDirectlySalesCompany ? address.detailAddress : address.address}`
                      }}
                    </a-select-option>
                    <template slot="dropdownRender" slot-scope="menu">
                      <v-nodes :vnodes="menu" />
                    <a-divider style="margin: 4px 0;" />
                      <div style="padding: 5px 15px 10px">
                        点击
                        <span @click="addContactDialog = true" style="color: #237ffa; cursor: pointer">“新增”</span>
                        按钮添加
                      </div>
                    </template>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-descriptions :style="{ padding: '0 16px' }">
              <a-descriptions-item label="销售模式">
                {{ saleContractInfo.saleModelDesc }}
              </a-descriptions-item>
              <a-descriptions-item label="业务类型">
                {{ saleContractInfo.businessTypeDesc }}
              </a-descriptions-item>
              <a-descriptions-item label="客户抬头">
                {{ saleContractInfo.customerHead }}
              </a-descriptions-item>
              <a-descriptions-item label="供应商抬头">
                {{ saleContractInfo.accountingUnit }}
              </a-descriptions-item>
              <a-descriptions-item label="最终用户">
                {{ saleContractInfo.finalCustomerName }}
              </a-descriptions-item>
              <a-descriptions-item label="发票类型">
                {{ saleContractInfo.invoiceTypeDesc }}
              </a-descriptions-item>
              <a-descriptions-item label="收款账号">
                {{ `${saleContractInfo.bankOfDeposit}_${saleContractInfo.accounts}` }}
              </a-descriptions-item>
              <a-descriptions-item label="结算方式">
                {{ PaymentMethodEnumMap[saleContractInfo.paymentMethod] }}
              </a-descriptions-item>
              <a-descriptions-item label="关联合同">
                {{ saleContractInfo.code }}
              </a-descriptions-item>
              <a-descriptions-item label="提货方式">
                <a-select v-model="saleOrder.deliveryMethod" placeholder="请选择" style="width: 120px">
                  <a-select-option :value="0">自提</a-select-option>
                  <a-select-option :value="1">商家发货</a-select-option>
                  <a-select-option :value="2">不限</a-select-option>
                </a-select>
              </a-descriptions-item>
              <a-descriptions-item label="是否含运">
                {{ saleContractInfo.isIncludedFare ? '是' : '否' }}
              </a-descriptions-item>
              <a-descriptions-item label="业务员">
                {{ saleContractInfo.salesmanName }}
              </a-descriptions-item>
            </a-descriptions>
          </a-form-model>
        </div>
        <div class="common">
          <div class="title gap-12">开单明细</div>
          <div style="display: flex; align-items: center">
            <a-button
              v-if="!['ASSET_MANAGEMENT'].includes(saleContractInfo.businessType)"
              type="primary"
              @click="handleDevice"
            >
              选择履约标的
            </a-button>
            <a-alert
              v-if="isDeviceEmpty"
              type="error"
              style="margin-left: 0.5em"
              message="履约标的不能为空"
              banner
              closable
            />
          </div>
          <a-form-model :model="saleOrder" ref="goodList" :rules="rules">
            <xz-table
              :columns="deviceColumns"
              :data="saleOrder.goodsList"
              rowKey="index"
              :bordered="true"
              :scroll="{ x: '100%' }"
            >
              <span slot="num" slot-scope="text, record, index">
                <a-form-model-item :prop="'goodsList.' + index + '.num'" :rules="rules.num">
                  <a-input-number
                    v-model="record.num"
                    placeholder="请输入"
                    :min="0"
                    :step="1"
                    @change="changeInput(index)"
                    style="width: 100%"
                  />
                </a-form-model-item>
              </span>
              <span slot="taxPrice" slot-scope="text, record, index">
                <a-form-model-item :prop="'goodsList.' + index + '.taxPrice'" :rules="rules.taxPrice">
                  <a-input-number
                    v-model="record.taxPrice"
                    placeholder="请输入"
                    :min="0"
                    :step="1"
                    @change="changeInput(index)"
                    style="width: 100%"
                  />
                </a-form-model-item>
              </span>
              <span slot="action" slot-scope="text, record, index">
                <a-popconfirm placement="leftTop" ok-text="确认" cancel-text="取消" @confirm="handleDelete(index)">
                  <template slot="title">确认删除？</template>
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </xz-table>
          </a-form-model>
        </div>
        <div class="common text">
          <span class="title">订单备注</span>
          <a-input v-model="saleOrder.remark" placeholder="请输入" allowClear style="width: 100%"></a-input>
        </div>
      </div>
      <div v-if="step === 'second'">
        <div class="common">
          <div class="title gap-12">开单基础信息</div>
          <a-descriptions :style="{ padding: '0 16px' }">
            <a-descriptions-item label="采购模式">
              {{ purchContractInfo.decisionDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="业务类型">
              {{ purchContractInfo.businessTypeDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="客户抬头">
              {{ purchContractInfo.unit }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商抬头">
              {{ purchContractInfo.supName }}
            </a-descriptions-item>
            <a-descriptions-item label="最终用户">
              {{ purchContractInfo.finalCustomerName }}
            </a-descriptions-item>
            <a-descriptions-item label="发票类型">
              {{ purchContractInfo.invoiceTypeDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="收款账号">
              {{ `${purchContractInfo.bankName}_${purchContractInfo.account}` }}
            </a-descriptions-item>
            <a-descriptions-item label="结算方式">
              {{ purchContractInfo.payWayDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="关联合同">
              {{ purchContractInfo.pcCode }}
            </a-descriptions-item>
            <a-descriptions-item label="提货方式">
              {{
                !saleOrder.deliveryMethod && saleOrder.deliveryMethod !== 0
                  ? ''
                  : DELIVERYMETHODENUM[saleOrder.deliveryMethod]
              }}
            </a-descriptions-item>
            <a-descriptions-item label="是否含运">
              {{ purchContractInfo.isCarry ? '是' : '否' }}
            </a-descriptions-item>
            <a-descriptions-item label="业务员">
              {{ purchContractInfo.salesmanName }}
            </a-descriptions-item>
            <a-descriptions-item v-if="this.$route.query.id" label="关联加盟订单"></a-descriptions-item>
            <a-descriptions-item label="收货地址">
              {{
                `${this.saleOrder.receiverProvinceName || ''}${this.saleOrder.receiverCityName || ''}${
                  this.saleOrder.receiverAreaName || ''
                }${this.saleOrder.receiverAddress || ''}`
              }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div class="common">
          <div class="title">开单明细</div>
          <xz-table
            :columns="purchDeviceColumns"
            :data="purchOrder.goodsList"
            rowKey="index"
            :scroll="{ x: '100%' }"
            :bordered="true"
          ></xz-table>
        </div>
        <div class="common text">
          <span class="title">订单备注</span>
          <a-input v-model="purchOrder.remark" placeholder="请输入" allowClear style="width: 100%"></a-input>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button @click="back()">取消</a-button>
      <a-button v-if="isDirectlySalesCompany || step === 'second'" @click="onSubmit(true)">保存</a-button>
      <!--<a-button @click="handleViewContract">
        合同预览
      </a-button>-->
      <a-button v-if="!isDirectlySalesCompany && step === 'first'" @click="handleSkip()">跳过</a-button>
      <a-button v-if="!isDirectlySalesCompany && step === 'first'" type="primary" @click="handleStep('second')">下一步</a-button>
      <a-button v-if="!isDirectlySalesCompany && step === 'second'" @click="handleStep('first')">上一步</a-button>
      <a-button v-if="isDirectlySalesCompany || step === 'second'" type="primary" @click="onSubmit(false)">提交</a-button>
    </div>
    <a-modal
      v-model="visible"
      :title="isPreviewContract ? '合同预览' : isDevice ? '履约标的' : ''"
      :width="900"
      :bodyStyle="{ padding: '0 12px' }"
      centered
      :maskClosable="false"
      :destroyOnClose="true"
    >
      <Devices
        ref="device"
        v-if="isDevice"
        :tableData="tableData"
        :selectedRows="selectedRows"
        :selectedIds="selectedIds"
        @handleData="handleDeviceData"
      ></Devices>
      <template slot="footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button type="primary" @click="handleOk">确认</a-button>
      </template>
    </a-modal>

    <!-- 新增联系人 -->
    <el-dialog title="新增联系人" :visible.sync="addContactDialog" @close="hideAddContactDialog">
      <el-form :model="contactForm" label-width="120px" :rules="contactRules" label-position="right" ref="contactForm">
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属客户：" prop="customerId">
              <div v-if="!isDirectlySalesCompany" style="display: flex; width: 100%">
                <customer-selector
                  style="flex: 1"
                  :name="contactForm.customerName"
                  @change="item => handleChange('customerId', item)"
                ></customer-selector>
              </div>
              <el-input v-else v-model="contactForm.customerName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人姓名：" prop="name">
              <el-input v-model="contactForm.name" clearable maxlength="20" placeholder="请输入联系人姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号：" prop="phone">
              <el-input v-model="contactForm.phone" clearable maxlength="11" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在地区：" prop="areaId">
              <area-cascader
                :provinceId.sync="contactForm.provinceId"
                :provinceName.sync="contactForm.provinceName"
                :cityId.sync="contactForm.cityId"
                :cityName.sync="contactForm.cityName"
                :areaId.sync="contactForm.areaId"
                :areaName.sync="contactForm.areaName"
                @handleValidate="handleChange('areaId')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="详细地址：" prop="address">
              <el-input v-model="contactForm.address" placeholder="请输入详细地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="text-align: right">
        <el-button plain @click="hideAddContactDialog">取消</el-button>
        <el-button type="primary" @click="saveContact">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import {
  saleOrderDetail,
  purchaseOrderDetail,
  saleContractDetail,
  purchaseContractDetail,
  contactPersonAddressList,
  getOneSkuByNameAndSecondServiceCategoryName,
  addSaleAndPurchOrder,
  updateSaleAndPurchOrder,
  xiaoziContactPersonAddressList,
} from '@/api/contract';
import ContactApi from '@/api/contact';
import PaymentMethodEnumMap from '@/enums/PaymentMethodEnum';
import _ from 'lodash';
import Devices from './Devices.vue';
import { mapGetters, mapActions } from 'vuex';
import CustomerSelector from '@/components/customerSelector.vue';
import AreaCascader from '@/components/common/areaCascader';
import directlySaleCompanyMixin from '../../mixins/directlySaleCompanyMixin';

const DELIVERYMETHODENUM = ['自提', '商家发货', '不限'];

const skuParams = {
  MAINTENANCE: {
    goodsName: '保养服务',
    secondServiceCategoryName: '保养',
  },
  SINGLE_REPAIR: {
    goodsName: '维修服务',
    secondServiceCategoryName: '维修服务',
  },
  WARRANTY: {
    goodsName: '保修服务',
    secondServiceCategoryName: '保修',
  },
  TECHNOLOGY_SERVICE: {
    goodsName: '技术服务',
    secondServiceCategoryName: '技术服务',
  },
  RELOCATION: {
    goodsName: '移机装机',
    secondServiceCategoryName: '移机装机',
  },
  ASSET_MANAGEMENT: {
    goodsName: '资产管理',
    secondServiceCategoryName: '资产管理',
  },
};

export default {
  name: 'NewSaleOrder',
  components: {
    xzTable,
    Devices,
    CustomerSelector,
    AreaCascader,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  mixins: [directlySaleCompanyMixin],
  computed: {
    ...mapGetters(['rateCache']),
    purchDeviceColumns () {
      const children
        = this.saleContractInfo.businessType !== 'ASSET_MANAGEMENT'
          ? [
            {
              title: '合同行',
              key: 'index',
              width: '78px',
              customRender: (text, record, index) => `${index + 1}`,
            },
            {
              title: '履约标的',
              dataIndex: 'desc',
              key: 'desc',
              width: '350px',
              ellipsis: true,
            },
            {
              title: '待开单金额',
              dataIndex: 'unOrderAmount',
              key: 'unOrderAmount',
              width: '120px',
              ellipsis: true,
              customRender: text => {
                return text ? (text * 1).toLocaleString() : '';
              },
            },
          ]
          : [
            {
              title: '合同行',
              key: 'index',
              width: '100px',
              customRender: (text, record, index) => `${index + 1}`,
            },
            {
              title: '待开单金额',
              dataIndex: 'unOrderAmount',
              key: 'unOrderAmount',
              width: '160px',
              ellipsis: true,
              customRender: text => {
                return text ? (text * 1).toLocaleString() : '';
              },
            },
          ];

      return [
        {
          title: '履约标的',
          children,
        },
        {
          title: '结算明细',
          children: [
            {
              title: '商品名称',
              dataIndex: 'goodsName',
              key: 'goodsName',
              width: '300px',
              ellipsis: true,
            },
            {
              title: '规格',
              dataIndex: 'specification',
              key: 'specification',
              width: '160px',
              ellipsis: true,
            },
            {
              title: '型号',
              dataIndex: 'model',
              key: 'model',
              width: '160px',
              ellipsis: true,
            },
            {
              title: '数量',
              dataIndex: 'num',
              key: 'num',
              width: '160px',
              ellipsis: true,
              scopedSlots: { customRender: 'num' },
            },
            {
              title: '单位',
              dataIndex: 'unitName',
              key: 'unitName',
              width: '120px',
              ellipsis: true,
            },
            {
              title: '单价',
              dataIndex: 'taxPrice',
              key: 'taxPrice',
              width: '160px',
              ellipsis: true,
              scopedSlots: { customRender: 'taxPrice' },
            },
            {
              title: '税率',
              dataIndex: 'taxRate',
              key: 'taxRate',
              width: '100px',
              ellipsis: true,
              customRender: text => {
                return text ? parseInt(text * 100) + '%' : '';
              },
            },
            {
              title: '金额',
              key: 'amount',
              width: '120px',
              ellipsis: true,
              customRender: (text, record) => {
                return record.num && record.taxPrice
                  ? Math.round(record.num * record.taxPrice * 1000000) / 1000000
                  : '';
              },
              customCell: record => {
                if (
                  Number(Math.round(record.num * record.taxPrice * 1000000) / 1000000 || '')
                  > Number(record.unOrderAmount)
                ) {
                  return { style: { color: 'red' } };
                }
              },
            },
          ],
        },
      ];
    },
    deviceColumns () {
      return [
        ...this.purchDeviceColumns,
        {
          title: '操作',
          key: 'action',
          width: 80,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ];
    },
  },
  data () {
    const validateNum = (rule, value, callback) => {
      if (value === '' || value === undefined) {
        callback(new Error('请输入'));
      } else if (value === 0) {
        callback(new Error('收款金额不能为0'));
      }

      callback();
    };
    const validateTel = (rule, value, callback) => {
      const regEx = /^1\d{10}$/;
      if (regEx.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的手机号'));
      }
    };

    return {
      isDevice: false,
      isPreviewContract: false,
      visible: false,
      wrapperCol: { span: 16 },
      form: {
        addressId: undefined,
      },
      saleOrder: {
        id: undefined,
        contractId: undefined,
        goodsList: [],
        deliveryMethod: undefined,
        remark: undefined,
        receiverAddress: undefined,
        receiverAreaId: undefined,
        receiverAreaName: undefined,
        receiverCityId: undefined,
        receiverCityName: undefined,
        receiverProvinceId: undefined,
        receiverProvinceName: undefined,
        receiverName: undefined,
        receiverPhone: undefined,
      },
      purchOrder: {
        contractId: undefined,
        goodsList: [],
        orderTotalAmount: undefined,
        remark: undefined,
      },
      currentRecord: null,
      saleContractInfo: {},
      purchContractInfo: {},
      addressInfoList: [],
      DELIVERYMETHODENUM,
      PaymentMethodEnumMap,
      step: 'first',
      customerName: undefined,
      rules: {
        addressId: [{ required: true, message: '请输入', trigger: 'change' }],
        num: [{ required: true, validator: validateNum, trigger: 'blur' }],
        taxPrice: [{ required: true, validator: validateNum, trigger: 'blur' }],
      },
      tableData: [],
      selectedRows: [],
      selectedIds: [],
      isDeviceEmpty: false,
      addContactDialog: false,
      contactForm: {
        customerId: '',
        customerName: '',
        name: '',
        phone: '',
        provinceId: '',
        provinceName: '',
        cityId: '',
        cityName: '',
        areaId: '',
        areaName: '',
        address: '',
        defaultFlag: true,
        isDefault: false,
      },
      contactRules: {
        customerId: [{ required: true, message: '请选择客户', trigger: 'blur' }],
        name: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validateTel, trigger: 'blur' },
        ],
        areaId: [{ required: true, message: '请选择地址', trigger: 'blur' }],
        address: [{ required: true, message: '请输入联系地址', trigger: 'blur' }],
      },
      timer: null,
    };
  },
  methods: {
    ...mapActions(['getRate']),
    handleChange (type, item) {
      if (type === 'customerId') {
        this.contactForm.customerId = item.id;
        this.contactForm.customerName = item.name;
      }
      this.$refs.contactForm.clearValidate(type);
    },
    hideAddContactDialog () {
      this.addContactDialog = false;
      this.$refs.contactForm.resetFields();
    },
    saveContact () {
      this.$refs.contactForm.validate(valid => {
        if (valid) {
          const api = this.isDirectlySalesCompany ? ContactApi.xiaoziAddContact : ContactApi.addContact;

          let data = this.isDirectlySalesCompany ? {
            customerId: this.contactForm.customerId,
            customerName: this.contactForm.customerName,
            provinceId: this.contactForm.provinceId,
            provinceName: this.contactForm.provinceName,
            cityId: this.contactForm.cityId,
            cityName: this.contactForm.cityName,
            areaId: this.contactForm.areaId,
            areaName: this.contactForm.areaName,
            detailAddress: this.contactForm.address,
            name: this.contactForm.name,
            mobilePhone: this.contactForm.phone,
          } : {
            customerId: this.contactForm.customerId,
            customerName: this.contactForm.customerName,
            name: this.contactForm.name,
            phone: this.contactForm.phone,
            defaultFlag: this.contactForm.defaultFlag,
            contactAddress: [
              {
                provinceId: this.contactForm.provinceId,
                provinceName: this.contactForm.provinceName,
                cityId: this.contactForm.cityId,
                cityName: this.contactForm.cityName,
                areaId: this.contactForm.areaId,
                areaName: this.contactForm.areaName,
                address: this.contactForm.address,
                isDefault: false,
              },
            ],
          };
          api(data).then(() => {
            this.$message({
              message: '新增成功',
              type: 'success',
            });
            this.hideAddContactDialog();
            this.getAddressList(this.saleContractInfo.customerHead);
          });
        }
      });
    },
    changeInput (index) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const item = this.saleOrder.goodsList[index];
        if (
          Number(Math.round(item.num * item.taxPrice * 1000000) / 1000000 || '') > Number(item.unOrderAmount)
        ) {
          this.$message({
            message: '开单金额不得大于待开单金额，请重新修改数量或单价',
            type: 'error',
          });
        }
      }, 1000);
    },
    handleDelete (index) {
      this.saleOrder.goodsList.splice(index, 1);
    },
    handleOk () {
      this.$refs.device.handleConfirm();
      this.visible = false;
    },
    handleDeviceData (v) {
      const newSelectedIds = v.map(d => d.model);

      const newDevices = _.cloneDeep(v.filter(d => !this.selectedIds.includes(d.model))); // 筛选出新勾选数据

      const toDeleteDeviceIds = this.selectedIds.filter(
        id => this.selectedIds.includes(id) && !newSelectedIds.includes(id),
      );

      this.saleOrder.goodsList.forEach((v, index) => {
        // 删除先前存在本次未被勾选的数据
        if (toDeleteDeviceIds.includes(v.model)) {
          this.saleOrder.goodsList.splice(index, 1);
        }
      });

      this.saleOrder.goodsList = [...this.saleOrder.goodsList, ...newDevices];
    },
    handleDevice () {
      this.isDevice = true;
      this.isPreviewContract = false;

      this.selectedIds = this.saleOrder.goodsList.map(v => v.model);

      this.selectedRows = this.tableData.filter(v => this.selectedIds.includes(v.model));

      this.visible = true;
    },
    back (to) {
      if (to) {
        this.$tabs.close({
          to,
          refresh: true,
        });
      } else {
        this.$router.back();
      }
    },
    handleViewContract () {
      this.isPreviewContract = true;
      this.isDevice = false;
      this.visible = true;
    },
    handleAddressChange () {
      const address = this.addressInfoList.find(v => v.addressId === this.form.addressId);

      if (address) {
        this.saleOrder.receiverProvinceId = address.provinceId;
        this.saleOrder.receiverProvinceName = address.provinceName;
        this.saleOrder.receiverCityName = address.cityName;
        this.saleOrder.receiverCityId = address.cityId;
        this.saleOrder.receiverAreaName = address.areaName;
        this.saleOrder.receiverAreaId = address.areaId;
        this.saleOrder.receiverAddress = this.isDirectlySalesCompany ? address.detailAddress : address.address;
        this.saleOrder.receiverName = address.name;
        this.saleOrder.receiverPhone = this.isDirectlySalesCompany ? address.mobilePhone : address.phone;
      }
    },
    validateBaseInfo () {
      return new Promise((resolve, reject) => {
        this.$refs.saleOrderForm.validate(valid => {
          if (valid) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    validateGoodsList () {
      this.isDeviceEmpty = !this.saleOrder.goodsList.length;

      return new Promise((resolve, reject) => {
        this.$refs.goodList.validate(valid => {
          if (valid && !this.isDeviceEmpty) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    handleSkip () {
      this.purchOrder.goodsList = _.cloneDeep(this.saleOrder.goodsList);

      let skus = {};

      if (!['ASSET_MANAGEMENT'].includes(this.purchContractInfo.businessType)) {
        (this.purchContractInfo.deviceList || []).forEach(sku => {
          skus[sku.saleContractDeviceId] = sku;
        });
      }

      this.purchOrder.goodsList.forEach(v => {
        const originTaxRate = v.taxRate;

        v.taxRate = !['ASSET_MANAGEMENT'].includes(this.purchContractInfo.businessType)
          ? skus[v.saleContractGoodsId].taxRate
          : v.taxRate;

        const isGeneralInvoice = this.purchContractInfo.invoiceType.includes('GENERAL');

        const tariff = originTaxRate ? originTaxRate : 0; // 销售税率
        const taxRate = isGeneralInvoice ? 0 : v.taxRate ? v.taxRate : 0; // 采购税率
        const rate = (1 - tariff) / (1 - taxRate);

        v.unOrderAmount = Math.round(v.unOrderAmount * (1 - this.rateCache) * rate * 1000000) / 1000000;
        v.taxPrice = v.taxPrice
          ? Math.round(v.taxPrice * (1 - this.rateCache) * rate * 1000000) / 1000000
          : undefined;
      });

      this.step = 'second';
    },
    async handleStep (step) {
      if (step === 'second') {
        await Promise.all([this.validateBaseInfo(), this.validateGoodsList()]);

        this.purchOrder.goodsList = _.cloneDeep(this.saleOrder.goodsList);

        let skus = {};

        if (!['ASSET_MANAGEMENT'].includes(this.purchContractInfo.businessType)) {
          (this.purchContractInfo.deviceList || []).forEach(sku => {
            skus[sku.saleContractDeviceId] = sku;
          });
        }

        this.purchOrder.goodsList.forEach(v => {
          const originTaxRate = v.taxRate;
          v.taxRate = !['ASSET_MANAGEMENT'].includes(this.purchContractInfo.businessType)
            ? skus[v.saleContractGoodsId].taxRate
            : v.taxRate;
          const isGeneralInvoice = this.purchContractInfo.invoiceType.includes('GENERAL');

          const tariff = originTaxRate ? originTaxRate : 0; // 销售税率
          const taxRate = isGeneralInvoice ? 0 : v.taxRate ? v.taxRate : 0; // 采购税率
          const rate = (1 - tariff) / (1 - taxRate);

          v.unOrderAmount = Math.round(v.unOrderAmount * (1 - this.rateCache) * rate * 1000000) / 1000000;

          v.taxPrice = Math.round(v.taxPrice * (1 - this.rateCache) * rate * 1000000) / 1000000;
        });
      }

      this.step = step;
    },
    async getSaleContractDetail (id) {
      const { body } = await saleContractDetail({ id, findDeviceFlag: true, findAssetItemFlag: true });

      this.saleContractInfo = body;
      this.saleOrder.contractId = body.id;

      if (this.isDirectlySalesCompany) {
        this.contactForm.customerName = this.saleContractInfo.customerHead;
        this.contactForm.customerId = this.saleContractInfo.customerId;
      }

      if (this.saleContractInfo.purchaseContractIds?.[0]) {
        this.getPurchContractDetail(this.saleContractInfo.purchaseContractIds[0]);
      }

      if (this.saleContractInfo.customerHead) {
        this.getAddressList(this.saleContractInfo.customerHead);
      }

      if (this.$route.query.id) {
        let skus = {};
        (body?.deviceList || []).forEach(sku => {
          skus[sku.saleContractDeviceId] = sku;
        });

        (this.saleOrder.goodsList || []).forEach(v => {
          const sku = skus[v.saleContractGoodsId];

          if (sku) {
            v.desc = `${sku.deviceName || '-'}/${sku.deviceModel || '-'}/${sku.deviceBrandName || '-'}/${
              sku.deviceSerialNumber || '-'
            }`;
            v.unOrderAmount = sku.unOrderAmount;
            v.uniqueValue = _.uniqueId();
          }
        });
      } else {
        this.saleOrder.goodsList = [];
      }

      if (!['ASSET_MANAGEMENT'].includes(this.saleContractInfo.businessType)) {
        this.getSkus();
      } else {
        this.tableData = [];
        this.getAssetSkus('维修服务');
        this.getAssetSkus('技术服务');
      }
    },
    async getPurchContractDetail (id) {
      const { body } = await purchaseContractDetail({
        id,
        findDeviceFlag: true,
        findGoodsFlag: true,
        findAssetItemFlag: true,
      });

      this.purchContractInfo = body;
      this.purchOrder.contractId = body.id;
    },
    async getPurchOrderDetail (purchaseOrderId) {
      const { body } = await purchaseOrderDetail({
        id: purchaseOrderId,
      });

      this.purchOrder.remark = body.remark;
    },
    async getSaleDetail () {
      const { body } = await saleOrderDetail({ id: this.$decode(this.$route.query.id), findDeviceFlag: true });

      this.saleOrder.id = body.id;
      this.saleOrder.contractId = body.contractId;
      this.saleOrder.goodsList = body.goodsList.map(v => ({
        ...v,
        saleContractGoodsId: v.contractGoodsId,
        uniqueValue: _.uniqueId(),
      }));

      this.customerName = body.customerHead;

      this.saleOrder.deliveryMethod = body.deliveryMethod;

      this.saleOrder.remark = body.remark;
      this.saleOrder.receiverProvinceId = body.receiverProvinceId;
      this.saleOrder.receiverProvinceName = body.receiverProvinceName;
      this.saleOrder.receiverCityName = body.receiverCityName;
      this.saleOrder.receiverCityId = body.receiverCityId;
      this.saleOrder.receiverAreaName = body.receiverAreaName;
      this.saleOrder.receiverAreaId = body.receiverAreaId;
      this.saleOrder.receiverAddress = body.receiverAddress;
      this.saleOrder.receiverName = body.receiverName;
      this.saleOrder.receiverPhone = body.receiverPhone;

      if (body.purchaseOrderId) {
        this.getPurchOrderDetail(body.purchaseOrderId);
      }

      this.getSaleContractDetail(body.contractId);
    },
    async getAddressList (customerName) {
      const api = this.isDirectlySalesCompany ? xiaoziContactPersonAddressList : contactPersonAddressList;

      const { body } = await api({
        customerName,
        pageNum: 1,
        pageSize: 100,
        customerStatus: this.isDirectlySalesCompany ? 'EFFECTIVE' : undefined,
      });

      this.addressInfoList = ((this.isDirectlySalesCompany ? body.list : body.records) || []).map(v => ({
        ...v,
        addressId: _.uniqueId(),
      }));

      if (this.$route.query.id) {
        const address = this.addressInfoList.find(
          address =>
            `${address.name}${address.provinceName}${address.cityName}${address.areaName}${this.isDirectlySalesCompany ? address.detailAddress : address.address}`
            === `${this.saleOrder.receiverName}${this.saleOrder.receiverProvinceName}${this.saleOrder.receiverCityName}${this.saleOrder.receiverAreaName}${this.saleOrder.receiverAddress}`,
        );

        if (address) {
          this.form.addressId = address.addressId;
        }
      }
    },
    async getAssetSkus (name) {
      const { body } = await getOneSkuByNameAndSecondServiceCategoryName({
        goodsName: name,
        secondServiceCategoryName: name,
      });

      (this.saleContractInfo.assetItemList || []).forEach(v => {
        if (v.name !== name) {
          return;
        }

        const sku = body || {};

        if (sku) {
          const uniqueValue = _.uniqueId();

          this.tableData.push({
            unOrderAmount: v.unOrderAmount,
            brandId: sku.brandId,
            brandName: sku.brandName,
            certificateNumber: sku.registerCode,
            goodsCode: sku.goodsCode,
            goodsName: sku.name,
            model: sku.model,
            isMedical: sku.medicalFlag,
            manufacturer: sku.enterpriseName,
            num: undefined,
            taxPrice: undefined,
            saleContractGoodsId: v.id,
            skuId: sku.id,
            specification: sku.specification,
            spuId: sku.spuId,
            taxRate: v.taxRate ? v.taxRate / 100 : undefined,
            unitName: sku.unitName,
            unitId: sku.unitId,
            uniqueValue,
          });

          if (this.$route.query.contractId) {
            this.saleOrder.goodsList.push({
              unOrderAmount: v.unOrderAmount,
              brandId: sku.brandId,
              brandName: sku.brandName,
              certificateNumber: sku.registerCode,
              goodsCode: sku.goodsCode,
              goodsName: sku.name,
              model: sku.model,
              isMedical: sku.medicalFlag,
              manufacturer: sku.enterpriseName,
              num: undefined,
              taxPrice: undefined,
              saleContractGoodsId: v.id,
              skuId: sku.id,
              specification: sku.specification,
              spuId: sku.spuId,
              taxRate: v.taxRate ? v.taxRate / 100 : undefined,
              unitName: sku.unitName,
              unitId: sku.unitId,
              uniqueValue,
            });
          }
        }
      });
    },
    async getSkus () {
      const { body } = await getOneSkuByNameAndSecondServiceCategoryName(skuParams[this.saleContractInfo.businessType]);

      this.tableData = [];

      (this.saleContractInfo.deviceList || []).forEach(v => {
        const sku = body || {};

        if (sku) {
          const uniqueValue = _.uniqueId();

          this.tableData.push({
            desc: `${v.deviceName || '-'}_${v.deviceModel || '-'}_${v.deviceBrandName || '-'}/${
              v.deviceSerialNumber || '-'
            }`,
            unOrderAmount: v.unOrderAmount,
            brandId: sku.brandId,
            brandName: sku.brandName,
            certificateNumber: sku.registerCode,
            goodsCode: sku.goodsCode,
            goodsName: sku.name,
            model: sku.model,
            isMedical: sku.medicalFlag,
            manufacturer: sku.enterpriseName,
            num: undefined,
            taxPrice: undefined,
            saleContractGoodsId: v.id,
            skuId: sku.id,
            specification: sku.specification,
            spuId: sku.spuId,
            taxRate: v.taxRate ? v.taxRate : undefined,
            unitName: sku.unitName,
            unitId: sku.unitId,
            uniqueValue,
          });

          if (this.$route.query.contractId) {
            this.saleOrder.goodsList.push({
              desc: `${v.deviceName || '-'}_${v.deviceModel || '-'}_${v.deviceBrandName || '-'}/${
                v.deviceSerialNumber || '-'
              }`,
              unOrderAmount: v.unOrderAmount,
              brandId: sku.brandId,
              brandName: sku.brandName,
              certificateNumber: sku.registerCode,
              goodsCode: sku.goodsCode,
              goodsName: sku.name,
              model: sku.model,
              isMedical: sku.medicalFlag,
              manufacturer: sku.enterpriseName,
              num: undefined,
              taxPrice: undefined,
              saleContractGoodsId: v.id,
              skuId: sku.id,
              specification: sku.specification,
              spuId: sku.spuId,
              taxRate: v.taxRate ? v.taxRate : undefined,
              unitName: sku.unitName,
              unitId: sku.unitId,
              uniqueValue,
            });
          }
        }
      });
    },
    async onSubmit (draftFlag) {
      if (this.isDirectlySalesCompany) {
        await Promise.all([this.validateBaseInfo(), this.validateGoodsList()]);
      }

      let purchOrder;

      if (!this.isDirectlySalesCompany) {
        purchOrder = {
          ...this.purchOrder,
          goodsList: this.purchOrder.goodsList.map(v => ({
            brandName: v.brandName,
            regNo: v.certificateNumber,
            goodsCode: v.goodsCode,
            goodsName: v.goodsName,
            model: v.model,
            isMedichair: v.isMedical === true ? 1 : v.isMedical === false ? 2 : undefined,
            producer: v.manufacturer,
            currentBillingNum: v.num,
            unitPrice: v.taxPrice,
            taxRate: v.taxRate,
            contractItemId: v.saleContractGoodsId,
            materialId: v.skuId,
            specification: v.specification,
            unitOfMsment: v.unitName,
            uniqueValue: v.uniqueValue,
          })),
        };

        purchOrder.orderTotalAmount = purchOrder.goodsList.reduce((pre, cur) => {
          return pre + cur.unitPrice * cur.currentBillingNum;
        }, 0);
      }

      const api = this.$route.query.id ? updateSaleAndPurchOrder : addSaleAndPurchOrder;

      await api({
        draftFlag,
        createPurchFlag: !this.isDirectlySalesCompany,
        purchOrder,
        saleOrder: this.saleOrder,
      });

      this.back(this.$route.query.id ? '/salesmanagement/billingOrder/list' : '/salesmanagement/salescontracts/list');
    },
  },
  created () {
    this.getRate();

    if (this.$route.query.contractId) {
      this.getSaleContractDetail(this.$decode(this.$route.query.contractId));
    } else if (this.$route.query.id) {
      this.getSaleDetail();
    }
  },
};
</script>

<style scoped lang="scss">
.order_template_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: auto;

    .gap-12 {
      margin-bottom: 12px;
    }

    .common {
      background-color: #fff;
      padding: 12px;
      margin-bottom: 6px;

      .title {
        font-weight: bold;
      }

      /deep/ .ant-form-item-label {
        float: left;
        width: 7em;
      }

      /deep/ .ant-table-content {
        .ant-form-item {
          margin-bottom: 12px;

          .ant-form-explain {
            display: none;
          }
        }

        .ant-table-tbody > tr > td {
          padding: 8px 16px 0;
          overflow-wrap: break-word;
        }
      }

      &.text {
        display: flex;
        align-items: center;

        span {
          width: 5em;
        }

        .ant-input {
          flex: 1;
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
    background-color: #fff;
    padding: 20px;

    .ant-btn {
      margin-left: 12px;
    }
  }
}
</style>

